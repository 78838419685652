<template>
  <div>
    <div class="d-flex flex-row">
      <div
        id="kt_profile_aside"
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      >
        <div class="card card-custom">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{ $t("settings.email") }}</h3>
            </div>
          </div>
          <div class="card-body pt-4">
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
            >
              <div
                v-for="(item, i) in tabNavigation"
                :key="i"
                class="navi-item mb-2"
              >
                <router-link
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  :to="{ name: item.route }"
                  custom
                >
                  <a
                    :href="href"
                    class="navi-link py-4"
                    :class="{ active: isExactActive }"
                  >
                    <span v-if="item.icon" class="navi-icon">
                      <v-icon>{{ item.icon }}</v-icon>
                    </span>
                    <span class="navi-text">{{ $t(item.label) }}</span>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-row-fluid ml-lg-8 client-details">
        <EmailTemplates v-if="$route.name === 'adminSettingsEmail'" />
        <LoginSecurity v-else-if="$route.name === 'adminSettingsSystemLogin'" />
      </div>
    </div>
  </div>
</template>

<style>
.disabledTab {
  display: none;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import EmailTemplates from "@/components/Admins/Settings/Email/MailTemplates/Index";
import LoginSecurity from "@/components/Admins/Settings/System/Security/LoginSecurity";

export default {
  name: "AdminsSettingsEmailIndex",
  components: {
    EmailTemplates,
    LoginSecurity
  },
  data() {
    return {
      client: {},
      tabIndex: 0,
      tabNavigation: [
        {
          route: "adminSettingsEmail",
          label: "emailSettings.emailTemplates",
          icon: "fal fa-shield"
        }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.adminSettings"),
        route: ""
      },
      {
        title: this.$t("menu.adminSettingsEmail"),
        route: ""
      },
      {
        title: this.$t("emailSettings.emailTemplates"),
        route: "/admin/settings/email"
      },
      {
        title: this.$t("general.overview"),
        route: "/admin/settings/email"
      }
    ]);
  },
  methods: {
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  }
};
</script>

<style lang="scss">
@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../node_modules/bootstrap/scss/mixins";
</style>
